<!--
 * @Author: mulingyuer
 * @Date: 2021-05-27 10:25:28
 * @LastEditTime: 2021-05-28 17:33:39
 * @LastEditors: mulingyuer
 * @Description: 转移弹窗
 * @FilePath: \saas-admin-vue\src\modules\portal\components\article-subject\article-list\TransferDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="转移" :visible.sync="show" width="500px" class="dialog-vertical transfer-dialog"
    @close="onClose('ruleForm')">
    <div class="content" v-loading="loading">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" size="medium"
        class="medium-form">
        <el-form-item label="专题分类：" prop="subject_id">
          <el-select v-model="form.subject_id" placeholder="请选择专题分类" @change="getColumnsData">
            <el-option v-for="op in subjectArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.subject_id!==''" label="专题栏目：" prop="new_column_id">
          <el-select v-model="form.new_column_id" placeholder="请选择专题栏目">
            <el-option v-for="op in columnsArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="show = false">取消</el-button>
      <el-button type="primary" size="small" :loading="saveLoading" @click="onSave('ruleForm')">保存
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {subjectAll, transferArticle,} from "../../../api/article-subject/article-list";
  import {columnsData} from "../../../api/article-subject/index";

  export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //专题id
    topicId: {
      type: [Number, String],
      required: true,
    },
    //转移的文章id
    transferId: {
      type: Array,
      required: true,
    },
    //确认后的回调
    complete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false, //加载中
      form: {
        subject_id: "", //专题分类
        new_column_id: "", //专题栏目
      },
      rules: {
        subject_id: [
          { required: true, message: "请选择专题分类", trigger: "change" },
        ],
        new_column_id: [
          { required: true, message: "请选择专题栏目", trigger: "change" },
        ],
      },
      subjectArr: [], //专题分类选项数据
      columnsArr: [], //栏目选项数据
      saveLoading: false, //保存中
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {
    //获取专题分类数据-所有专题
    getSubjectAll() {
      this.loading = true;
      subjectAll()
        .then((res) => {
          const { data } = res;
          this.subjectArr = data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //获取专题下的栏目数据
    getColumnsData() {
      this.loading = true;
      const postData = { id: this.form.subject_id };
      columnsData(postData)
        .then((res) => {
          const { data } = res;
          this.columnsArr = data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //保存
    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const postData = {
            article_id: this.transferId, //文章id数组
            subject_id: this.topicId, //专题id
            new_column_id: this.form.new_column_id, //栏目id
          };
          transferArticle(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              this.saveLoading = false;
              this.complete(); //回调
              this.show = false;
            })
            .catch(() => {
              this.saveLoading = false;
            });
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //关闭弹窗回调
    onClose(formName) {
      this.resetForm(formName);
    },
  },
  created() {
    this.getSubjectAll();
  },
};
</script>

<style lang="scss" scoped>
.transfer-dialog {
  .content {
    padding-top: 20px;
  }
}
</style>
