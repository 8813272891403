<!--
 * @Author: mulingyuer
 * @Date: 2021-05-26 17:16:16
 * @LastEditTime: 2021-05-28 17:16:08
 * @LastEditors: mulingyuer
 * @Description: 文章列表
 * @FilePath: \saas-admin-vue\src\modules\portal\views\article-subject\article-list.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page article-list">
    <div class="head">
      <div class="head-group-btn top-btn">
        <el-button type="primary" size="small" @click="onAdd">新增</el-button>
      </div>
      <ListFilter :filter-change="onFilter" :exportData="postData" :topicId="id" />
    </div>
    <div class="list-content content" v-loading="loading">
      <div class="list-btn">
        <el-button size="small" @click="onMultipleTransfer">转移</el-button>
        <el-button size="small" @click="onMultipleDelete">删除</el-button>
      </div>
      <el-table class="thead-light" :data="tbody" style="width: 100%" stripe @sort-change="sortChange"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column v-for="th in thead" :key="th.prop" :prop="th.prop" :label="th.label"
          :minWidth="th.minWidth" :sortable="th.sort" :fixed="th.fixed" :show-overflow-tooltip="th.tooltip">
          <template slot-scope="{row}">
            <!-- 操作 -->
            <template v-if="th.prop==='operate'">
              <el-button type="text" size="mini" @click="onTransfer([row.article_id])">转移</el-button>
              <el-button type="text" size="mini" @click="onDelete(row.article_id)">删除</el-button>
            </template>
            <!-- 其他 -->
            <template v-else>{{row[th.prop] | placeholder}}</template>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="onChangPage" />
    </div>
    <!-- 转移弹窗 -->
    <TransferDialog v-model="openTransferDialog" :topicId="id" :transferId="transferData"
      :complete="getArticleList" />
    <!-- 新增文章弹窗 -->
    <AddArticleDialog v-model="openAddArticleDialog" :topicId="id" :complete="getArticleList" />
  </div>
</template>

<script>
  import ListFilter from "../../components/article-subject/article-list/Filter";
  import Pagination from "@/base/components/Default/Pagination";
  import TransferDialog from "../../components/article-subject/article-list/TransferDialog";
  import AddArticleDialog from "../../components/article-subject/index/AddArticleDialog";
  import {articleList, delArticles,} from "../../api/article-subject/article-list";

  export default {
  props: {
    //专题id
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      loading: false, //加载中
      postData: {}, //提交的数据
      //表头
      thead: [
        { label: "栏目", prop: "column_name", minWidth: 180, tooltip: true },
        {
          label: "文章标题",
          prop: "article_title",
          minWidth: 400,
          tooltip: true,
        },
        { label: "文章分类", prop: "category_name", minWidth: 100 },
        { label: "来源", prop: "source_name", minWidth: 100 },
        { label: "作者", prop: "author_name", minWidth: 100 },
        { label: "发布时间", prop: "post_time", minWidth: 180, sort: "custom" },
        { label: "操作", prop: "operate", minWidth: 120, fixed: "right" },
      ],
      tbody: [], //表格数据
      pageData: {}, //分页数据
      activeArticleIds: [], //选中的文章id
      openTransferDialog: false, //是否显示转移弹窗
      transferData: [], //转移的文章id
      openAddArticleDialog: false, //是否显示新增弹窗
    };
  },
  methods: {
    //获取文章列表数据
    getArticleList(pageData) {
      this.loading = true;
      articleList(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //筛选事件
    onFilter(filterObj) {
      Object.assign(this.postData, filterObj, { page: 1 });
      this.getArticleList();
    },
    //分页事件
    onChangPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getArticleList();
    },
    // 表格排序事件
    sortChange({ prop, order }) {
      const is_desc = order === "ascending" ? 0 : 1;
      Object.assign(this.postData, { is_desc, order_by: prop }, { page: 1 });
      this.getArticleList();
    },
    //表格多选事件
    handleSelectionChange(arr) {
      if (arr.length) {
        this.activeArticleIds = arr.map((item) => item.article_id);
      } else {
        this.activeArticleIds = [];
      }
    },
    //移除
    onDelete(article_id, multiple = false) {
      let infoText = `您是否要删除${multiple ? "选中的" : "该条"}文章数据？`;
      this.$confirm(infoText, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          this.loading = true;
          const postData = { subject_id: this.id, article_id };
          delArticles(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);
              this.getArticleList();
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          //略过
        });
    },
    //多选删除
    onMultipleDelete() {
      if (this.activeArticleIds.length <= 0) {
        return this.$message("没有检测到勾选的数据！");
      }
      this.onDelete(this.activeArticleIds, true);
    },
    //单个转移
    onTransfer(idArr) {
      this.transferData = idArr;
      this.openTransferDialog = true;
    },
    //多选转移
    onMultipleTransfer() {
      if (this.activeArticleIds.length <= 0) {
        return this.$message("没有检测到勾选的数据！");
      }
      this.onTransfer(this.activeArticleIds);
    },
    //新增
    onAdd() {
      this.openAddArticleDialog = true;
    },
  },
  created() {
    Object.assign(this.postData, { id: this.id }); //增加专题id数据
    this.getArticleList();
  },
  components: {
    ListFilter,
    Pagination,
    TransferDialog,
    AddArticleDialog,
  },
};
</script>

<style lang="scss" scoped>
.article-list {
  .head {
    margin-bottom: 24px;
  }
}
</style>
