/*
 * @Author: mulingyuer
 * @Date: 2021-05-26 17:52:13
 * @LastEditTime: 2021-06-11 14:37:02
 * @LastEditors: aleaner
 * @Description:文章列表
 * @FilePath: \article-admin\api\article-subject\article-list.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";
import download from "@/base/utils/download";


//文章列表导出
export const exportFile = data => {
  return download({
    url: "/admin/portal/subject/excelArticles",
    method: "post",
    data,
    download: true
  })
};

//文章列表数据
export const articleList = data => {
  return api({
    url: "/admin/portal/subject/showArticles",
    method: "post",
    data
  })
};


//移除
export const delArticles = data => {
  return api({
    url: "/admin/portal/subject/delArticles",
    method: "post",
    data
  })
};

//所有专题数据
export const subjectAll = data => {
  return api({
    url: "/admin/portal/subject/all",
    method: "post",
    data
  })
};

//转移
export const transferArticle = data => {
  return api({
    url: "/admin/portal/subject/moveArticles",
    method: "post",
    data
  })
};