<!--
 * @Author: mulingyuer
 * @Date: 2021-05-26 17:32:43
 * @LastEditTime: 2021-05-28 17:29:10
 * @LastEditors: mulingyuer
 * @Description: 文章列表筛选
 * @FilePath: \saas-admin-vue\src\modules\portal\components\article-subject\article-list\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :onFilter="onFilter" :onReset="onReset" :onExport="onExport" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="70px" size="medium" class="filter-from">
      <div class="filter-item">
        <DatePeriodPicker label="发布时间：" start-placeholder="开始日期" :start-time.sync="form.start_time"
          start-prop="start_time" end-placeholder="结束日期" :end-time.sync="form.end_time" end-prop="end_time" />
      </div>
      <div class="filter-item">
        <el-form-item label="栏目名称：" prop="column_id">
          <el-select v-model="form.column_id" placeholder="请选择栏目">
            <el-option v-for="op in columnsArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文章标题：" prop="article_title">
          <el-input v-model="form.article_title" placeholder="请输入文章标题"></el-input>
        </el-form-item>
        <el-form-item label="文章分类：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择文章分类">
            <el-option v-for="op in categoryArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
  import LayoutFilter from "@/base/layout/LayoutFilter";
  import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
  import {columnsData, subjectCategory,} from "../../../api/article-subject/index";
  import {exportFile} from "../../../api/article-subject/article-list";

  export default {
  props: {
    //筛选回调
    filterChange: {
      type: Function,
      required: true,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
    //专题id
    topicId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      form: {
        start_time: -1, //发布开始
        end_time: -1, //发布结束时间
        column_id: "", //栏目名称
        article_title: "", //文章标题
        category_id: -1, //文章分类
      },
      rules: {},
      //分类选项数据
      categoryArr: [{ id: -1, name: "全部" }],
      //栏目选项数据
      columnsArr: [{ id: -1, name: "全部" }],
    };
  },
  methods: {
    //获取分类数据
    getSubjectCategory() {
      subjectCategory()
        .then((res) => {
          const { data } = res;
          this.categoryArr.push(...data);
        })
        .catch(() => {});
    },
    //获取栏目选项数据
    getSubjectColumns() {
      const postData = { id: this.topicId };
      columnsData(postData)
        .then((res) => {
          const { data } = res;
          this.columnsArr.push(...data);
        })
        .catch(() => {});
    },
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //重置
    onReset() {
      this.$refs["ruleForm"].resetFields();
      return this.onFilter();
    },
    //导出
    onExport() {
      return exportFile({
        ...this.exportData,
        ...this.form,
      }).catch(() => {});
    },
  },
  created() {
    this.getSubjectCategory();
    this.getSubjectColumns();
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
