import { render, staticRenderFns } from "./article-list.vue?vue&type=template&id=69e58581&scoped=true&"
import script from "./article-list.vue?vue&type=script&lang=js&"
export * from "./article-list.vue?vue&type=script&lang=js&"
import style0 from "./article-list.vue?vue&type=style&index=0&id=69e58581&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69e58581",
  null
  
)

export default component.exports